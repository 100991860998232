.no-display-on-phone {
  @media (max-width: 37.5em) {
    display: none;
  }
}
.no-display-on-ipad {
  @media (max-width: 48em) {
    display: none;
  }
}
.no-display-on-desktop {
  @media (min-width: 48em) {
    display: none;
  }
}
.MuiDialog-container {
  height: 200px;
}
.MuiDialog-scrollPaper {
  height: 200px;
}
.css-hz1bth-MuiDialog-container {
  height: 200px;
}

.circle {
  background-color: $color-gray-light-5;
  border-radius: 100%;
  height: 1.5em;
  width: 1.5em;
  margin: auto;
}

.rounded {
  position: relative;
  padding-bottom: 1em;
  border-radius: 5px;
}
.m-single {
  margin-right: 4em;
  @include respond(phone) {
    margin-bottom: 1em;
  }
  @include respond(tab-port) {
    margin-right: 3em;
    margin-bottom: 1em;
  }
  p {
    margin-top: 1em;
    span {
      color: $color-black;
      font-size: 1.7em;
      font-weight: bold;
    }
  }
}
.small-box {
  // margin: 0.5em;
  background-color: #fff;
}
.border-line {
  height: 120px;
  border: 1px solid #eff2f7;
  box-sizing: border-box;
  border-radius: 10px;
}
.manage-store {
  @include respond(tab-port) {
    padding: 2em;
  }
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url("../../assets/icons/SearchIcon.png");
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .overview {
    .circle {
      background-color: $color-gray-light-5;
      border-radius: 100%;
      height: 1.5em;
      width: 1.5em;
      margin: auto;
    }
    .m-single {
      margin-right: 4em;
      @include respond(phone) {
        margin-bottom: 1em;
      }
      @include respond(tab-port) {
        margin-right: 3em;
        margin-bottom: 1em;
      }
      p {
        margin-top: 1em;
        span {
          color: $color-black;
          font-size: 1.7em;
          font-weight: bold;
        }
      }
    }
  }
  .border-line {
    width: 128px;
    height: 110px;
    border: 1px solid #eff2f7;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .count {
    text-align: center;
    margin-right: 1.5em;
    padding: 1em;
    p {
      &:nth-of-type(1) {
        font-size: 2.2em;
        margin-bottom: -0.2em;
      }
    }
  }
  .setup {
    @include respond(tab-port) {
      margin-top: 1.5em;
    }
    .circle {
      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        border: 1px solid #a0b1c4;
        margin-right: 0.5em;
      }
    }
  }
}

th {
  color: $color-gray !important;
  font-weight: normal;
}

td.online {
  color: $color-primary !important;
}

td {
  line-height: 3;
}

// No Store
.no-store {
  .cardhead {
    @include respond(tab-port) {
      padding: 1.5em !important;
    }
    button {
      float: right;
      @include respond(phone) {
        float: initial;
      }
    }
    input {
      background-image: url("../../assets/icons/SearchIcon.png");
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      // border: .2px solid $color-gray-light !important;
      border: none;
      padding-left: 3em;
      width: 100%;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .center-box {
    width: 45% !important;
    @include respond(tab-land) {
      width: 90% !important;
    }
    .circle {
      background-color: $color-gray-light-2;
      height: 5em;
      width: 5em;
      border-radius: 100%;
      align-content: center;
      margin: auto;
    }
    p {
      text-align: center;
      &:nth-of-type(1) {
        font-size: 1.6em;
        font-weight: 400;
      }
      &:nth-of-type(2) {
        color: $color-gray;
      }
    }
    button {
      padding: 0.8em;
    }
  }
}
.store-form-header {
  color: #6f972d;
}
.store-details {
  .form-container {
    label {
      color: $color-gray;
    }
    .input {
      background-color: inherit;
      border: 2px solid $color-gray-light-3;
      box-sizing: border-box;
      border-radius: 5px;
      line-height: 3;
      padding-left: 0.5em;
      width: 100%;
      margin-top: -0.5em;
      &:focus {
        outline-color: $color-primary;
      }
      &::placeholder {
        color: $color-gray;
      }
    }
    select {
      background-color: inherit;
      border: 2px solid $color-gray-light-3;
      box-sizing: border-box;
      border-radius: 5px;
      padding-left: 0.5em;
      width: 100%;
      margin-top: -0.5em;
      &:focus {
        outline-color: $color-primary;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .file-upload {
      p {
        color: $color-gray;
      }
      .file-box {
        border: 2px solid $color-gray-light-3;
        width: 12em;
        min-height: 2.5em;
        margin-right: 1em;
        img {
          width: 100%;
        }
      }
      label {
        color: $color-secondary;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
      svg {
        margin-top: 0.5em;
        color: $color-secondary;
        margin-right: 0.5em;
      }
    }
  }
}
.invoice-overview {
  .bg-cardInvoice {
    background: #ffffff;
    border: 0.5px solid #a0b1c4;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .invoiceImg {
      width: 22.625rem;
      height: 19.125rem;
      background: #c4c4c4;
    }
  }
  .table-info {
    margin-top: 6.063rem;
  }
  .btn-send {
    background: #6f972d;
    border-radius: 10px;
    width: 7.063rem;
    height: 3.125rem;
  }
  .btn-edit {
    border: 1px solid gray;
    border-radius: 8px;
  }
}
.all-stores {
  background: #f2f9eb;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
.store-block {
  display: flex;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
}
.bg-store {
  width: 282px;
  height: 117px;
  background: #ffffff;
  border-radius: 5px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.store-name {
  font-size: 13px;
}
.btn-none-desktop {
  @media screen and(min-width: 700px) {
    display: none;
  }
}
.product-count {
  width: 100%;
}
.bg-card-min {
  width: 100%;
  height: 100%;
  background: #f2f9eb;
  border-radius: 5px;
}
.round-border {
  border: 1px solid #eff2f7;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.circle-mobile {
  background-color: $color-gray-light-5;
  border-radius: 100%;
  height: 1.5em;
  width: 1.5em;
  margin: auto;
}
.viewstoremobile {
  @media screen and (min-width: 900px) {
    display: none;
  }
}

.borderOutline {
  max-width: 130px;
  height: 100%;
  border: 1px solid #eff2f7;
  text-align: center;
  border-radius: 10px;

  .info {
    font-size: 11px;
    color: #a0b1c4;
  }
}
