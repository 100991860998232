$color-primary: #6f972d; //using
$color-primary-light: #F2F9EB; //using
$color-secondary: #F69033; // using
$color-secondary-light: #f6701d;
$color-secondary-light-2:#FFD782; //using
$color-dark: #c4c4c4;
$color-dark-2: #313131;
$color-gray-light-1: #f2f2f2;
$color-gray-light-2: #F8F9FB;
$color-gray-light-3: #E5E9F2; //using
$color-gray-light-4: #FCFDFB; //using
$color-gray-light-5: #DFDEDE; //using
$color-gray-light: #A0B1C4; //using
$color-gray-dark-1: #777;
$color-gray-dark-2: #999;
$color-gray-dark-3: #333;
$color-white: #fff;
$color-blue: #40BFFF; //using
$color-black: #000; //using
$color-gold: #fab428;
$color-red: #F63333;
$color-social: #173460;
$default-font-size: 1.6rem;
$color-gray: #9DA8B6; //using
$color-gray-2: #DFDEDE; //
$color-blue-light: #f6fffe; //using
// Grey Line below

$gray-line: 1px solid $color-gray-light-2;

$shadow-1: -2px -3px 4px 2px rgba($color-white, 0.8),
  1px 1px 2px 2px rgba($color-black, 0.3);

$shadow-2: 0 0 16px 6px rgba($color-gray-light-3, 0.65);
