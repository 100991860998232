// List Orders
.list-orders {
  .invoices {
    background: #f2f9eb;
    padding: 1em;
    margin-bottom: 0.5em;
    position: relative;
    .invoice-overviews {
      display: flex;
      justify-content: center;
      margin-top: 1em;

      .invoice-summary {
        display: flex;
        flex-wrap: wrap;
        flex: 0.6;
        width: 100%;
        .invoice-reports {
          width: 25%;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: #fff;
          margin: 0.5em;
          height: 87px;
          border-radius: 5px;
          h6 {
            margin-top: 2.5em;
            font-size: 12px;
          }
          @include respond(phone) {
            flex-direction: column;
            width: 40%;
          }
        }
      }
      .invoice-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: #a0b1c480;
        height: 38px;
        font-size: 12px;
        margin-top: 0.5em;
        margin-right: 0.5em;
        flex: 0.4;
        .invoice-btn {
          background-color: #fff;
          padding: 0.2em 0.5em;
          border-radius: 0.3em;
        }
        .invoice-btn {
          border: none;
          background: transparent;
        }
        .invoice-date_today,
        .invoice-date_month,
        .invoice-date_week,
        .invoice-date_year {
          width: 20%;
          text-align: center;
        }
        .invoice-date_today {
          .invoice-btn {
            margin: 0.1em;
            background-color: #fff;
          }
        }
        @include respond(phone) {
          display: none;
          flex-direction: column;
        }
      }
      @include respond(phone) {
        display: none;
      }
    }
    @include respond(phone) {
      display: none;
    }
  }
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      height: 3em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .status {
    .status-single {
      background-color: inherit;
      border: none;
      text-align: center;
      padding: 0.6em;
      color: $color-gray-light;
      margin-right: 1.3em;
      small {
        color: $color-black;
        font-size: 0.7em;
      }
      &.active {
        border-bottom: 0.1em solid $color-primary;
      }
      &:hover,
      &:active,
      &:focus {
        border-bottom: 0.1em solid $color-primary;
      }
    }
  }
  .filter {
    margin-bottom: 2em;
    @include respond(tab-port) {
      padding: 1em;
      margin: auto;
    }
    .filter-single {
      display: flex;
      border: none;
      align-items: center;
      background-color: $color-primary-light;
      border-radius: 0.5em;
      margin-right: 1em;
      height: 3.5em;
      &:hover {
        cursor: pointer;
      }
      @include respond(tab-land) {
        margin-bottom: 1em;
        margin-left: 0 !important;
      }
      select,
      .date {
        background-color: inherit;
        border: none;
        margin-top: 0.8em;
        font-size: 0.9em;
        &:active,
        &:focus {
          outline: none !important;
        }
        @include respond(phone) {
          width: 100% !important;
        }
        &:focus,
        &:active {
          outline: inherit !important;
        }
        & > option {
          background-color: $color-primary-light;
          border: none;
          padding: 0.5em;
        }
      }

      .date {
        margin-top: 2em;
      }
      .icon {
        margin-top: 1.2em !important;
        margin-right: 1em;
      }
    }
    button {
      border: none;
      background-color: $color-primary-light;
      border-radius: 0.5em;
      width: 100% !important;
      height: 4em;
      font-size: 0.9em;
      @include respond(tab-land) {
        margin-left: -0.8em;
      }
    }
    @include respond(phone) {
      display: none;
    }
  }

  .sort-results {
    display: none;
    @include respond(phone) {
      display: block;
    }

    .sort {
      display: flex;
      justify-content: space-around;
      margin-top: 13px;
      button {
        border: none;
        background-color: transparent;
        color: #252b1d;

        &.active {
          border-bottom: 1px solid #6f972d;
        }
        &:hover {
          border-bottom: 1px solid #6f972d;
        }
        small {
          margin-left: 1em;
        }
      }
    }
    .sort-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 21px;
      margin-right: 21px;
      p {
        color: #a0b1c4;
        font-size: 10px;
        margin-top: 14px;
      }
      .filter-calenders {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f9eb;
        height: 41px;
        width: 100%;
        border-radius: 1px;

        .date {
          margin-top: 12px;
          background-color: transparent;
          border: none;
          font-size: 10px;
          width: 6em;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  // order table mobile
  .phone-display {
    display: none;
  }
  @include respond(phone) {
    .phone-display {
      display: block;
      margin: 10px auto;
      padding: 1em;
      box-shadow: 0px 2px 10px 0px #0000001a;
      width: 100%;
      margin-top: 10px;

      .phone-invoice {
        display: flex;
        flex-direction: column;
        width: 100%;

        .invoice-number {
          display: flex;
          align-items: center;
          margin-top: 24px;
          .label {
            background: #ffe4c5;
            border: none;
            padding: 6px 15px;
            border-radius: 30px;
            font-size: 10px;
            color: #dd7700;
            margin-top: -13px;

            margin-left: 3em;
          }
        }

        .invoice-order {
          display: flex;
          align-items: center;
          height: 65px;
          a {
            text-align: center;
            margin-left: 2.5em;
            margin-top: -1.5em;
            margin-right: 15px;
            padding: 0.5em 1em;
            border-radius: 0.5em;
            text-transform: capitalize;
            color: #000;
            background: #f2f9eb;
          }
        }

        .invoice-number span {
          margin-left: 15px;
          color: #223263;
        }

        .invoice-number h6 {
          font-weight: bold;
          margin-top: -0.5em;
        }

        .invoice-item p,
        .invoice-price p,
        .invoice-store p,
        .invoice-shipping p,
        .invoice-date p,
        .invoice-order p {
          color: #a0b1c4;
        }
        .invoice-item span {
          margin-left: 50px;
          color: #223263;
        }
        .invoice-price span {
          margin-left: 60px;
          color: #40bfff;
        }
        .invoice-date span {
          margin-left: 50px;
          color: #223263;
        }
        .invoice-store span {
          margin-left: 55px;
          color: #223263;
        }
        .invoice-shipping span {
          margin-left: 25px;
          color: #223263;
        }
        .invoice-order span {
          margin-left: 20px;
          color: #223263;
        }
      }
    }
  }
}

// List Orders
.order-details {
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      height: 3em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
    .action-btn {
      button.btn {
        @include respond(phone) {
          width: 100% !important;
        }
      }
    }
  }
}
