// Product Overview
.product-overview {
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .circle {
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid $color-gray;
      margin-right: 0.5em;
    }
  }
  .setup {
    @include respond(phone) {
      margin-top: 2em;
    }
  }
  .m-single {
    background-color: $color-white;
    border-radius: 0.5em;
    padding: 0.7em;
    height: 6em;
    margin-right: 1.3em;
    &.click {
      cursor: pointer;
    }
    .icon {
      margin-left: 0.7em;
      margin-right: 1em;
    }
    .report {
      margin: 0.6em auto;
      width: 65%;
      p {
        font-size: 0.8rem;
        &:nth-of-type(1) {
          color: $color-gray-light;
          margin-bottom: -0.01em;
        }
        &:nth-of-type(2) {
          font-size: 1.2em;
        }
      }
    }
  }
  .overview {
    .product-card {
      background-color: $color-white;
      padding: 0.7em;
      border-radius: 0.5em;
      .product-name {
        margin-bottom: -0.001em;
      }
      .details {
        margin-left: 1em;
        p {
          &:nth-of-type(2) {
            font-size: 1em;
          }
        }
      }
      .link {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.product-card {
  background-color: $color-white;
  padding: 0.7em;
  border-radius: 0.5em;
  .product-name {
    margin-bottom: -0.001em;
  }
  .details {
    margin-left: 1em;
    p {
      &:nth-of-type(2) {
        font-size: 1em;
      }
    }
  }
  .link {
    &:hover {
      cursor: pointer;
    }
  }
}
// No Product
.no-product {
  margin-top: 1.5em;
  .cardhead {
    @include respond(tab-port) {
      padding: 1.5em !important;
    }
    @include respond(phone) {
      padding: 0em !important;
    }
    button {
      float: right;
      @include respond(phone) {
        display: none;
        float: initial;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      // border: .2px solid $color-gray-light !important;
      border: none;
      padding-left: 3em;
      width: 100%;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }

  .center-box {
    width: 45% !important;
    padding-top: 13%;

    @include respond(tab-land) {
      width: 90% !important;
      padding-top: 22%;
    }

    @include respond(tab-port) {
      padding-top: 14%;
    }
    @include respond(phone) {
      padding-top: 0%;
    }
    .circle {
      background-color: $color-gray-light-2;
      height: 5em;
      width: 5em;
      border-radius: 100%;
      align-content: center;
      margin: auto;
    }

    p {
      text-align: center;
      &:nth-of-type(1) {
        font-size: 1.6em;
        font-weight: 400;
        @include respond(phone) {
          font-size: 1.1em;
          font-weight: 500;
        }
      }
      &:nth-of-type(2) {
        color: $color-gray;
        @include respond(phone) {
          font-size: 0.8em;
        }
        @include respond(tiny) {
          font-size: 0.7em;
        }
      }
    }

    button {
      padding: 0.8em;
      @include respond(phone) {
        margin-top: 65%;
      }
      @include respond(tiny) {
        margin-top: 20%;
      }
    }
  }
}

.product-details {
  .cardhead {
    @include respond(tab-port) {
      padding: 1.5em !important;
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      // border: .2px solid $color-gray-light !important;
      border: none;
      padding-left: 3em;
      width: 100%;
      height: 3em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .text-big {
    font-size: 1.12em !important;
  }
  .additional {
    select {
      margin-left: 1em;
      margin-top: -0.6em;
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        margin: 0;
        margin-bottom: 1em;
      }
    }
  }
  .table-responsive {
    table {
      margin: auto;
      // border: 1px solid $col;
    }
    th {
      text-align: center;
      padding: 1em;
    }
    td {
      padding: 0.7em;
    }
  }
  .product-action {
    width: 50%;
    margin: auto;
    text-align: center !important;
    @include respond(tab-land) {
      width: 90%;
    }
    div {
      :nth-of-type(1) {
        padding-left: 1em;
      }
    }
  }
  .action-btn {
    width: fit-content;
    margin: auto;
    @include respond(tab-port) {
      width: 100%;
      margin-left: 0.5em;
    }
    button {
      margin-right: 1em;
      background-color: inherit;
      padding: 0.6em;
      border-radius: 0.5em;
      width: 10em;
      @include respond(phone) {
        margin: auto;
        margin-bottom: 1em;
        margin-top: 1em;
      }
      &:nth-of-type(1) {
        border: 1px solid $color-red;
        color: $color-red;
        &:hover {
          background-color: $color-red;
          color: $color-white;
        }
      }
      &:nth-of-type(2) {
        border: 1px solid $color-primary;
        color: $color-primary;
        &:hover {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
  .return-policy {
    div {
      text-align: justify;
    }
  }
  .color-single {
    margin-right: 1em;
    .color-box {
      height: 1.7em;
      width: 5em;
    }
  }
  .size-single {
    border: 1px solid $color-primary;
    height: 1.7em;
    width: 5em;
    text-align: center;
    margin-right: 1em;
  }
  .description {
    p {
      text-align: justify;
    }
  }
  .modal-content {
    background-color: $color-secondary-light-2;
    border: none;
    width: 10%;
  }
  .modal-action {
    width: fit-content;
    margin: auto;
    align-content: center;
    p {
      &:hover {
        cursor: pointer;
      }
      &:nth-of-type(1) {
        margin-right: 5em;
        color: $color-red;
      }
    }
  }
}

// Highest Revenue
.highest-revenue {
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      height: 3em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .circle {
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid $color-gray;
      margin-right: 0.5em;
    }
  }
  .setup {
    @include respond(phone) {
      margin-top: 2em;
    }
  }
  .m-single {
    background-color: $color-white;
    border-radius: 0.5em;
    padding: 0.7em;
    height: 6em;
    margin-right: 1.3em;
    &.click {
      cursor: pointer;
    }
    .icon {
      margin-left: 0.7em;
      margin-right: 1em;
    }
    .report {
      margin: 0.6em auto;
      width: 65%;
      p {
        font-size: 0.8rem;
        &:nth-of-type(1) {
          color: $color-gray-light;
          margin-bottom: -0.01em;
        }
        &:nth-of-type(2) {
          font-size: 1.2em;
        }
      }
    }
  }
  .overview {
    .product-card {
      background-color: $color-white;
      padding: 0.7em;
      border-radius: 0.5em;
      .product-name {
        margin-bottom: -0.001em;
      }
      .details {
        margin-left: 1em;
        p {
          &:nth-of-type(2) {
            font-size: 1em;
          }
        }
      }
      .link {
        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }
}

// List Products
.list-products {
  .cardhead {
    width: 100% !important;
    select {
      // width: 15em;
      margin-right: 2em;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }
    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 100%;
      height: 3em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  .status {
    .status-single {
      background-color: inherit;
      border: none;
      text-align: center;
      padding: 0.6em;
      color: $color-gray-light;
      margin-right: 1.3em;
      margin-top: 1em;
      small {
        color: $color-black;
        font-size: 0.7em;
      }
      &.active {
        border-bottom: 0.1em solid $color-primary;
      }
      &:hover,
      &:active,
      &:focus {
        border-bottom: 0.1em solid $color-primary;
      }
      @include respond(phone) {
        margin-left: 0.4em;
      }
    }
    @include respond(phone) {
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
    @include respond(tiny) {
      width: 100%;
      font-size: 11px;
    }
  }

  .form {
    position: relative;
    .microsoft {
      position: absolute;
      right: 3em;
      bottom: 1em;
      @include respond(phone) {
        padding: 2em auto;
        bottom: -3em;
        right: 0.5em;
      }
    }
    @include respond(phone) {
      margin-bottom: 3em;
    }
  }
  .filters {
    margin-bottom: 2em;

    @include respond(tab-port) {
      padding: 1em;
      margin: auto;
    }
    .filter-single {
      border: none;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: $color-primary-light;
      border-radius: 0.5em;
      margin: 1em;

      height: 3em;

      &:hover {
        cursor: pointer;
      }
      @include respond(tab-land) {
        margin-bottom: 1em;
      }
      select {
        background-color: inherit;
        border: none;
        font-size: 0.9em;
        &:active,
        &:focus {
          outline: none !important;
        }
        @include respond(smallest) {
          width: 85% !important;
          // margin: 0.1em;
          // padding: 0.3em;
        }
      }
      .date {
        background-color: inherit;
        border: none;
        font-size: 0.9em;
        &:active,
        &:focus {
          outline: none !important;
        }
        @include respond(smallest) {
          width: 85% !important;
          // margin: 0.1em;
          // padding: 0.3em;
        }
      }
      .icon {
        margin-top: 1.2em !important;
        margin-right: 1em;
        @include respond(phone) {
          display: none;
        }
      }
      @include respond(tab-land) {
        width: 30% !important;
        margin-left: 0.8em;
      }
      @include respond(phone) {
        width: 35% !important;
        margin-right: 1em;
        margin-top: 1.5em;
        // padding: 0.3em;
      }
      @include respond(iphone) {
        width: 100% !important;
      }
    }
    button {
      border: none;
      background-color: $color-primary-light;
      border-radius: 0.5em;
      width: 100% !important;
      height: 3em;
      font-size: 0.9em;
      @include respond(tab-land) {
        margin-left: -0.8em;
      }
    }

    @include respond(phone) {
      justify-content: space-between;
      width: 100%;
      height: 13em;
      font-size: 10px;
    }
    @include respond(smallest) {
      width: 100% !important;
      font-size: 16px;
    }
  }
  .product-desktop{
    @include respond(phone)
    {
      display: none;
    }
  }
  .product-mobile {
    display: none;
    @include respond(phone) {
      display: flex;
      flex-direction: column;
      border: 1px solid #eff2f7;
      padding: 0 0 1em 1.8em;
      border-radius: 5px;
      margin-bottom: 1em;

      .product-content {
        display: flex;
        justify-content: center;
        width: 100%;

        .product-info {
          flex: 0.7;
          p {
            line-height: 15px;
            color: #9da8b6;
            margin-top: 0.5em;

            .name {
              margin-left: 4.4em;
              color: #231f20;
              font-size: 0.8em;
            }
            .price {
              margin-left: 4.8em;
              color: #231f20;
            }
            .amount {
              margin-left: 5em;
              color: #231f20;
            }
            .date {
              margin-left: 2.5em;
              color: #231f20;
            }
          }
        }
        .product-image {
          flex: 0.3;
          img {
            width: 7em;
            height: 8em;
            object-fit: fill;
          }
        }
      }
      .product-status {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 1em 0.1em;
        p {
          color: #9da8b6;
          span {
            color: #231f20;
            margin-left: 0.2em;
            padding-right: 0.6em;
            font-size: 12px;
          }
        }
      }
    }
    @include respond(tiny) {
      .product-content {
        width: 100%;
        .product-info {
          p {
            line-height: 1em;
            .name {
              margin-left: 3.7em;
            }
            .price {
              margin-left: 4.2em;
            }
            .amount {
              margin-left: 4.4em;
            }
            .date {
              margin-left: 1.8em;
            }
          }
        }
        .product-image {
          img {
            height: 7em;
            width: 6em;
          }
        }
      }
      .product-status {
        margin: 0.5em 0.1em;
        p {
          font-size: 0.6em;
          span {
            margin-left: 0.5em;
          }
        }
      }
    }
  }
  @media screen and (max-width: 384px) {
    width: 100%;
  }
}

// Product Creation
.product-creation {
  margin-top: 4em;
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      //   width: 70%;

      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }
    @include respond(phone) {
      .color-size {
        justify-content: space-between;
      }
    }
  }
  .empty {
    height: 90%;
    @include respond(phone) {
      .row {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .dropdown-feature {
    background-color: $color-primary-light;
    border: none;
    padding: 1em;
    @include respond(phone) {
      width: 100% !important;
    }
  }
  .features {
    height: 100% !important;
  }
  .publish {
    background-color: $color-primary-light;
    width: 98%;
    margin: auto;
    .text {
      display: flex;
      border-radius: 0.2em;
      margin: auto;
      margin-top: 2em;
      flex-wrap: wrap;
    }
    @include respond(tab-land) {
      width: 100%;
    }
    @include respond(phone) {
      width: 100%;
    }
    @include respond(tiny) {
      width: 100%;
      .published {
        display: flex;
        flex-direction: column;
      }
      .switched {
        margin-left: 70%;
      }
    }
  }
  .input {
    background-color: inherit;
    border: 2px solid $color-gray-light-3;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 3;
    padding-left: 0.5em;
    width: 100%;
    margin-top: -0.5em;
    &:focus {
      outline-color: $color-primary;
    }
    &::placeholder {
      color: $color-gray;
    }
  }
  .form-btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: auto;
    button {
      width: 12em;
      border-radius: 0.5em !important;
      @include respond(phone) {
        font-size: 0.6em;
        margin-bottom: 1em;
      }
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

// Product price
.product-price {
  margin-top: 4em;
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }

    .size-color {
      display: flex;
      align-items: center;
    }
    .react_color {
      display: flex;
      flex-direction: column;
      @include respond(phone) {
        .show {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .colorField {
          display: flex;
          // flex-direction: column;
        }
      }
    }
    .feat {
      display: flex;
      align-items: center;
      width: 100%;
      .btn {
        background: #2d5e97;
      }
      .input {
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 3;
        padding-left: 0.5em;
        width: 100%;
        margin-top: -0.5em;
        &:focus {
          outline-color: $color-primary;
        }
        &::placeholder {
          color: $color-gray;
        }
      }
      @include respond(tab-land) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @include respond(phone) {
        width: 100%;
        display: flex;
      }

      // .letter {
      //   width: 45%;
      //   padding-top: 0.5em;
      //   // margin: 1em;
      //   p {
      //     font-size: 13px;
      //     svg {
      //       margin-right: 0.5em;
      //       background: #6f972d;
      //       color: #fff;
      //       border-radius: 70%;
      //       border: none;
      //     }
      //   }
      //   @include respond(phone) {
      //     display: none;
      //   }
      // }
      .mobile {
        @include respond(phone) {
          display: flex;
        }
      }
    }
  }

  .shadow {
    p:nth-child(1) {
      @include respond(phone) {
        display: none;
      }
    }
    .mobile {
      @include respond(phone) {
        display: flex;
      }
    }
    .price-range {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .price-range-forth {
        @include respond(phone) {
          display: none;
        }
      }
      @include respond(phone) {
        justify-content: space-between;
        margin-left: 1em;
      }
    }
  }

  .form-btn-action {
    width: fit-content;

    button {
      border-radius: 0.8em !important;
    }
    @include respond(phone) {
      display: flex;
      justify-content: center;
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

// Product sample
.product-sample {
  width: 60%;
  margin-left: auto;
  margin-right: auto;

  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }

    .input {
      background-color: inherit;
      border: 2px solid $color-gray-light-3;
      box-sizing: border-box;
      border-radius: 5px;
      line-height: 3;
      padding-left: 0.5em;
      width: 100%;
      margin-top: -0.5em;
      &:focus {
        outline-color: $color-primary;
      }
      &::placeholder {
        color: $color-gray;
      }
    }

    .feat {
      display: flex;
      width: 100%;
      .input {
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 3;
        padding-left: 0.5em;
        width: 100%;
        margin-top: -0.5em;
        &:focus {
          outline-color: $color-primary;
        }
        &::placeholder {
          color: $color-gray;
        }
      }
      @include respond(tab-land) {
        display: flex;
        justify-content: center;
      }
      @include respond(phone) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .react_color {
      display: flex;
      flex-direction: column;
      @include respond(phone) {
        .show {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .colorField {
          display: flex;
        }
      }
    }
  }

  .radio {
    font-size: 12px;
    input {
      margin-left: 1em;
    }
  }

  .form-btn-action {
    width: fit-content;
    margin: auto;
    button {
      width: 9em;
      border-radius: 0.8em !important;
      @include respond(phone) {
      }
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

// Arrow Head
.arrow-head {
  display: none;
  @include respond(phone) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: -4em;
    margin-bottom: 0.8em;
    cursor: pointer;
    button {
      border: none;
      background: transparent;
      svg {
        height: 1.8em;
        width: 1.8em;
        background: yellowgreen;
        color: #fff;
        border-radius: 50%;
      }
    }
    p {
      margin-bottom: -0.2em;
    }
    svg {
      height: 2em;
      width: 2em;
      border-radius: 50%;
    }
  }
}

// Arrow Heads
.arrow-heads {
  display: none;

  @include respond(phone) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -4em;
    margin-bottom: 0.8em;
    cursor: pointer;
    a {
      p {
        color: #000;
      }
      svg {
        height: 1.8em;
        width: 1.8em;
        background: yellowgreen;
        color: #fff;
        border-radius: 50%;
      }
    }
    button {
      border: none;
      background: transparent;
      svg {
        height: 1.8em;
        width: 1.8em;
        background: yellowgreen;
        color: #fff;
        border-radius: 50%;
      }
    }
    p {
      margin-bottom: -0.2em;
    }
    svg {
      height: 2em;
      width: 2em;
      border-radius: 50%;
    }
  }
}

// product config
.product-config {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }

    .feat {
      display: flex;
      width: 100%;
      .select {
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 3;
        padding: 1em;
        width: 100%;
        margin-top: -0.5em;
        &:focus {
          outline-color: $color-primary;
        }
        &::placeholder {
          color: $color-gray;
        }
      }
      @include respond(tab-land) {
        display: flex;
        justify-content: center;
      }
      @include respond(phone) {
        width: 100%;
        display: flex;
        font-size: 12px;
      }

      .hides {
        @include respond(phone) {
          display: none;
        }
      }
    }
  }

  .shipping {
    @include respond(phone) {
      display: none;
    }
  }

  .form-btn-action {
    width: fit-content;
    margin: auto;
    button {
      width: 9em;
      border-radius: 0.8em !important;
      @include respond(phone) {
      }
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

// product-model
.product-model {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }

    .feat {
      display: flex;
      width: 100%;
      .select {
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 3;
        padding: 1em;
        width: 100%;
        margin-top: -0.5em;
        &:focus {
          outline-color: $color-primary;
        }
        &::placeholder {
          color: $color-gray;
        }
      }
      @include respond(tab-land) {
        display: flex;
        justify-content: center;
      }
      @include respond(phone) {
        width: 100%;
        display: flex;
      }
    }
    .add-image-video {
      svg {
        margin-right: 0.8em;
        color: #fff;
        background-color: #6f972d;
        border-radius: 50%;
      }
    }

    .publish {
      .published {
        p {
          font-size: 12px;
        }
      }
    }
  }

  .form-btn-action {
    width: fit-content;
    margin: auto;
    button {
      width: 9em;
      border-radius: 0.8em !important;
      @include respond(phone) {
      }
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

// Product logistics
.product-logististics {
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  .card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 1.3em !important;
    }

    .feat {
      display: flex;
      width: 100%;
      .input {
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 3;
        padding-left: 0.5em;
        width: 100%;
        margin-top: -0.5em;
        &:focus {
          outline-color: $color-primary;
        }
        &::placeholder {
          color: $color-gray;
        }
      }
      @include respond(tab-land) {
        display: flex;
        justify-content: center;
      }
      @include respond(phone) {
        width: 100%;
        display: flex;
      }
    }
  }

  .form-btn-action {
    width: fit-content;
    margin: auto;
    button {
      width: 9em;
      border-radius: 0.8em !important;
      @include respond(phone) {
      }
    }
  }

  @include respond(tab-port) {
    margin-top: 0.5em;
    width: 100%;
  }
  @include respond(phone) {
    margin-top: 0.5em;
    width: 100%;
  }
}

.invoice-overviews {
  display: flex;
  width: 100%;
  padding: 0.5em;
  flex-direction: column;
  align-items: center;

  .cardhead {
    display: flex;
    width: 100%;

    .manage-product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .store-search {
        flex: 0.9;
      }

      .btn {
        @include respond(phone) {
          margin-top: -4em;
          margin-right: 1em;
        }
      }
    }
    select {
      width: 100%;
      border: none;

      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(tab-port) {
        display: flex;
        width: 8em;
      }
      @include respond(phone) {
        display: flex;
      }
    }

    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 90%;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
      @include respond(tab-port) {
        width: 23em;
      }
      @include respond(phone) {
        width: 18em !important;
      }

      @include respond(tiny) {
        width: 14em !important;
      }
    }

    @include respond(tab-land) {
      width: 100% !important;
    }
  }

  .circle {
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid $color-gray;
      margin-right: 0.5em;
    }
  }
  .setup {
    @include respond(phone) {
      margin-top: 2em;
    }
  }
  .status {
    .status-single {
      background-color: inherit;
      border: none;
      text-align: center;
      padding: 0.2em;
      color: $color-gray-light;
      margin: 1.3em;
      small {
        color: $color-black;
        font-size: 0.7em;
      }
      &.active {
        border-bottom: 0.1em solid $color-primary;
      }
      &:hover,
      &:active,
      &:focus {
        border-bottom: 0.1em solid $color-primary;
      }
    }
    @include respond(phone) {
      display: none;
      justify-content: space-evenly;
      font-size: 15px;
    }
  }
  .rounded {
    position: relative;
    padding-bottom: 1em;
    border-radius: 5px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        margin-top: 15px;
        margin: 0.2em 0.5em;
      }

      @include respond(phone) {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: rgba(160, 177, 196, 0.5);
      width: 50%;
      border-radius: 5px;

      button {
        border-radius: 5px;
        padding: 0.5em;
        margin: 5px 10px;
        background-color: transparent;
        border: none;

        &.active,
        &:hover {
          background-color: #fff;
        }
        p {
          text-align: center;
          margin-bottom: -5px;
        }
      }
      @include respond(tab-land) {
        width: 50%;
        font-size: 14px;
      }
      @include respond(phone) {
        width: 95%;
        font-size: 14px;
      }
    }

    .row {
      display: flex;
      border-radius: 5px;

      .m-single {
        background-color: $color-white;
        border-radius: 0.5em;
        text-align: center;
        padding: 0.7em;
        height: 65px;
        width: 30%;
        margin-top: 30px;
        margin-left: 25px;

        &.click {
          cursor: pointer;
        }

        .report {
          margin: 1em 1.5em;
          font-size: 10px;
          width: 60%;
          p {
            font-size: 0.8rem;
            &:nth-of-type(1) {
              color: $color-gray-light;
              margin-bottom: -0.01em;
            }
            &:nth-of-type(2) {
              font-size: 1.2em;
            }
          }
          @include respond(phone) {
            width: 105px;
            text-align: center;
            margin-top: 0.7em;
          }
        }
        @include respond(phone) {
          display: flex;
          width: 45%;
          height: 56px;
          margin: 0.2em;
          padding: 0.2em;
          border-radius: 0.5em;
        }
      }
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  .filter {
    margin-bottom: 1em;

    .filter-status {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 18%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    .filter-calender {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 22%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      .date {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
        margin-left: 0.8em;
      }
    }

    .filter-product {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 19%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    .filter-other {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 22%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    @include respond(tab-port) {
      margin: auto;
    }

    @include respond(phone) {
      display: none;
    }
  }

  .product-revenue {
    width: 100%;
    @media (min-width: 768px) {
      width: 94%;
    }
    .overview {
      width: 100%;
      .header {
        font-size: 14px;
        margin-left: 0.8em;
      }
      .product-card {
        background-color: $color-white;
        padding: 0.7em;
        border-radius: 0.5em;
        height: 85px;
        width: 90%;
        margin: auto;
        border-radius: 4px;

        .product-name {
          margin-bottom: -0.001em;

          .product-text {
            font-size: 14px;
          }

          @include respond(phone) {
            font-size: 12px;
            width: 60%;
          }
        }
        .details {
          margin-left: 0.5em;
          p {
            &:nth-of-type(2) {
              font-size: 1em;
            }
          }
        }
        .link {
          font-size: 14px;
          margin-top: 5px;
          width: 25%;
          &:hover {
            cursor: pointer;
          }

          @include respond(phone) {
            font-size: 9px;
          }
        }
        @include respond(tab-land) {
          width: 90%;
        }

        @include respond(tab-port) {
          width: 90%;
        }
      }
    }
  }

  .sort-results {
    display: none;
    @include respond(phone) {
      display: block;
    }

    .sort {
      display: flex;
      justify-content: space-around;
      margin-top: 13px;
      button {
        border: none;
        background-color: transparent;
        color: #252b1d;

        &.active {
          border-bottom: 1px solid #6f972d;
        }
        &:hover {
          border-bottom: 1px solid #6f972d;
        }
        small {
          margin-left: 1em;
        }
      }
    }
    .sort-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 21px;
      margin-right: 21px;
      p {
        color: #a0b1c4;
        font-size: 10px;
        margin-top: 14px;
      }
      .filter-calenders {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f9eb;
        height: 41px;
        width: 100%;
        border-radius: 1px;

        .date {
          margin-top: 12px;
          background-color: transparent;
          border: none;
          font-size: 10px;
          width: 6em;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .landing-toggle,
  .invoice-table {
    width: 100%;

    .invoice-btn {
      background-color: #fff;
    }
  }

  .invoices {
    width: 100%;
    padding: 1em 2em;
    margin-bottom: 0.5em;
    display: flex;
    .product-info {
      position: relative;
      background: #f2f9eb;
      flex: 0.7;
      width: 100%;
      padding: 2em;
      padding-bottom: 4em;

      .product-header {
        display: flex;
        h5 {
          flex: 0.5;
          @include respond(tab-land) {
            width: 100%;
            font-size: 1em;
            font-weight: bold;
          }
        }
        .invoice-date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background: #a0b1c480;
          height: 2.8em;
          margin-top: 0.5em;
          margin-right: 0.5em;
          flex: 0.5;
          .invoice-btn {
            background-color: #fff;
            padding: 0.2em 0.5em;
            border-radius: 0.3em;
          }
          .invoice-btn {
            border: none;
            background: transparent;
          }
          .invoice-date_today,
          .invoice-date_month,
          .invoice-date_week,
          .invoice-date_year {
            width: 20%;
            text-align: center;
          }
          .invoice-date_today {
            .invoice-btn {
              margin: 0.1em;
              background-color: #fff;
            }
          }
        }
        @include respond(phone) {
          display: flex;
          flex-direction: column;
        }
      }
      .product-overviews {
        display: flex;
        margin-top: 1em;
        flex-wrap: wrap;
        width: 100%;

        .invoice-reports {
          width: 30%;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: #fff;
          margin: 0.5em;
          height: 5em;
          border-radius: 5px;
          h6 {
            font-size: 12px;
            margin-top: 2em;
            text-align: center;
          }
          p {
            display: flex;
          }
          @include respond(tab-land) {
            width: 40%;
          }

          @include respond(smallest) {
            width: 40%;
          }
        }

        @include respond(phone) {
          width: 100%;
          justify-content: space-around;
          
        }
      }
      .microsoft {
        position: absolute;
        bottom: 1em;
        right: 4em;
        @include respond(phone) {
          right: 0em;
          bottom: 0.2em;
        }
      }
      @media screen and (max-width: 500px) {
        padding: 2em 0.3em;
        margin-top: 0em;
      }
    }
    .profile {
      flex: 0.3;
      padding: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f9eb;
      margin-bottom: 1em;
      width: 100%;
      @media (min-width: 768px) {
        margin-left: 0.5em;
        height: 100%;
      }
      .profile-header {
        .hide {
          display: none;
        }
        @include respond(phone) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 0.5px solid #a0b1c4;
          // margin: 1em;
          h5 {
            font-size: 14px;
          }
          .hide {
            display: flex;
            margin-bottom: 1em;
          }
        }
      }

      .profile-info {
        margin-left: 0.2em;

        .profile-prog {
          padding-left: 3em;
        }

        ul {
          margin-top: 1em;
          li {
            margin: 1em;
          }
        }
      }
    }

    @include respond(phone) {
      display: flex;
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 384px) {
      width: 100%;
      padding: 0.1em;
      // font-size: 10px;
    }
  }
}
