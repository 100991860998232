.acct-overview {
    img {
        margin-right: .5em;
    }
 
    .single {
        &:hover {
            cursor: pointer;
            background-color: $color-primary-light;
        }
    }
}