// Invoice Overview
.invoice-overview {
  display: flex;
  width: 100%;
  padding: 0 0.5em;
  flex-direction: column;
  align-items: center;

  .cardhead {
    display: flex;
    width: 100%;

    select {
      width: 50%;
      border: none;

      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(tab-port) {
        display: flex;
        width: 8em;
      }
      @include respond(phone) {
        display: flex;
        width: 100% !important;
      }
    }

    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 25em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
      @include respond(tab-port) {
        width: 25em;
      }
      @include respond(phone) {
        width: 100% !important;
      }
    }

    @include respond(tab-land) {
      width: 100% !important;
    }

    @include respond(phone) {
      width: 95% !important;
    }
  }

  .circle {
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid $color-gray;
      margin-right: 0.5em;
    }
  }
  .setup {
    @include respond(phone) {
      margin-top: 2em;
    }
  }
  .status {
    .status-single {
      background-color: inherit;
      border: none;
      text-align: center;
      padding: 0.2em;
      color: $color-gray-light;
      margin: 0em 1.3em 1em 0;
      small {
        color: $color-black;
        font-size: 0.7em;
      }
      &.active {
        border-bottom: 0.1em solid $color-primary;
      }
      &:hover,
      &:active,
      &:focus {
        border-bottom: 0.1em solid $color-primary;
      }
    }
    // @include respond(phone) {
    //   display: none;
    //   justify-content: space-evenly;
    //   font-size: 15px;
    // }
  }
  // .toggle-section {
  //   @include respond(phone) {
  //     display: none;
  //   }
  // }
  .rounded {
    position: relative;
    padding-bottom: 1em;
    border-radius: 5px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        margin-top: 15px;
        margin: 0.2em 0.5em;
      }

      @include respond(phone) {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: rgba(160, 177, 196, 0.5);
      width: 50%;
      border-radius: 5px;

      button {
        border-radius: 5px;
        padding: 0.5em;
        margin: 5px 10px;
        background-color: transparent;
        border: none;

        &.active,
        &:hover {
          background-color: #fff;
        }
        p {
          text-align: center;
          margin-bottom: -5px;
        }
      }
      @include respond(tab-land) {
        width: 50%;
        font-size: 14px;
      }
      @include respond(phone) {
        width: 95%;
        font-size: 14px;
      }
    }
    // @include respond(phone) {
    //   width: 335px;
    // }
    .row {
      display: flex;
      border-radius: 5px;

      .m-single {
        background-color: $color-white;
        border-radius: 0.5em;
        text-align: center;
        padding: 0.7em;
        height: 65px;
        width: 30%;
        margin-top: 30px;
        margin-left: 25px;

        &.click {
          cursor: pointer;
        }

        .report {
          margin: 1em 1.5em;
          font-size: 10px;
          width: 60%;
          p {
            font-size: 0.8rem;
            &:nth-of-type(1) {
              color: $color-gray-light;
              margin-bottom: -0.01em;
            }
            &:nth-of-type(2) {
              font-size: 1.2em;
            }
          }
          @include respond(phone) {
            width: 105px;
            text-align: center;
            margin-top: 0.7em;
          }
        }
        @include respond(phone) {
          display: flex;
          width: 45%;
          height: 56px;
          margin: 0.2em;
          padding: 0.2em;
          border-radius: 0.5em;
        }
      }

      .microsoft {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 30%;
        height: 35px;
        position: absolute;
        right: 30px;
        bottom: 15px;

        svg {
          margin-left: 0.4em;
        }
        .btn {
          font-size: 12px;
        }
      }

      @include respond(phone) {
        .microsoft {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 30%;
          height: 35px;
          position: absolute;
          right: 2px;
          bottom: 15px;
          padding-bottom: 2em;

          svg {
            margin-left: 0.4em;
          }
        }
      }
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  .filter {
    margin-bottom: 1em;

    .filter-status {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 18%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    .filter-calender {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 22%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      .date {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
        margin-left: 0.8em;
      }
    }

    .filter-product {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 19%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    .filter-other {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 22%;
      background-color: $color-primary-light;
      margin-top: 2em;

      border-radius: 10px;
      select {
        border: none;
        background-color: $color-primary-light;
        margin-top: 1em;
      }
    }

    @include respond(tab-port) {
      margin: auto;
    }

    @include respond(phone) {
      display: none;
    }
  }

  .overview {
    .product-card {
      background-color: $color-white;
      padding: 0.7em;
      border-radius: 0.5em;
      height: 85px;
      width: 100%;
      border-radius: 4px;

      .product-name {
        margin-bottom: -0.001em;

        .product-text {
          font-size: 14px;
        }

        @include respond(phone) {
          font-size: 12px;
          width: 60%;
        }
      }
      .details {
        margin-left: 0.5em;
        p {
          &:nth-of-type(2) {
            font-size: 1em;
          }
        }
      }
      .link {
        font-size: 14px;
        margin-top: 5px;
        width: 25%;
        &:hover {
          cursor: pointer;
        }

        @include respond(phone) {
          font-size: 12px;
        }
      }
      @include respond(tab-land) {
        width: 100%;
      }

      @include respond(tab-port) {
        width: 100%;
      }
    }
  }

  .sort-results {
    display: none;
    @include respond(phone) {
      display: block;
    }

    .sort {
      display: flex;
      justify-content: space-around;
      margin-top: 13px;
      button {
        border: none;
        background-color: transparent;
        color: #252b1d;

        &.active {
          border-bottom: 1px solid #6f972d;
        }
        &:hover {
          border-bottom: 1px solid #6f972d;
        }
        small {
          margin-left: 1em;
        }
      }
    }
    .sort-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 21px;
      margin-right: 21px;
      p {
        color: #a0b1c4;
        font-size: 10px;
        margin-top: 14px;
      }
      .filter-calenders {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f9eb;
        height: 41px;
        width: 100%;
        border-radius: 1px;

        .date {
          margin-top: 12px;
          background-color: transparent;
          border: none;
          font-size: 10px;
          width: 6em;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .invoice-reports {
    width: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    margin: 0.5em;
    height: 87px;
    border-radius: 5px;
    h6 {
      margin-top: 2.5em;
      font-size: 12px;
    }
  }
  .landing-toggle,
  .invoice-table {
    width: 100%;

    .invoice-btn {
      background-color: #fff;
    }
  }

  .invoices {
    background: #f2f9eb;
    // padding: 1em;
    margin: auto;
    position: relative;
    .invoice-overviews {
      display: flex;
      justify-content: center;
      margin-top: 1em;

      .invoice-summary {
        display: flex;
        flex-wrap: wrap;
        flex: 0.6;
        width: 100%;
        .invoice-reports {
          width: 25%;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: #fff;
          margin: 0.5em;
          height: 87px;
          border-radius: 5px;
          h6 {
            margin-top: 2.5em;
            font-size: 12px;
          }
          @include respond(phone) {
            flex-direction: column;
            width: 44%;
          }
        }
      }
      .invoice-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: #a0b1c480;
        height: 38px;
        margin-top: 0.5em;
        margin-right: 0.5em;
        flex: 0.4;
        .invoice-btn {
          background-color: #fff;
          padding: 0.2em 0.5em;
          border-radius: 0.3em;
        }
        .invoice-btn {
          border: none;
          background: transparent;
        }
        .invoice-date_today,
        .invoice-date_month,
        .invoice-date_week,
        .invoice-date_year {
          width: 20%;
          text-align: center;
        }
        .invoice-date_today {
          .invoice-btn {
            margin: 0.1em;
            background-color: #fff;
          }
        }
        @include respond(phone) {
          display: none;
          flex-direction: column;
        }
      }
      @include respond(phone) {
        display: flex;
        flex-direction: column;
      }
    }
    .microsoft {
      position: absolute;
      display: flex;
      align-items: center;
      right: 20px;
      bottom: 15px;
      .btn {
        color: #a0b1c4;
      }
      @include respond(phone) {
        // font-size: 10px;
        align-items: flex-start;
        width: 45%;
      }
      @include respond(tab-port) {
        // padding-top: 2em;
        bottom: 1em;
      }
    }
  }

  // Invoice table mobile
  .phone-display {
    display: none;
  }
  @include respond(phone) {
    .phone-display {
      display: block;
      margin: 10px auto;
      padding: 1em;
      box-shadow: 0px 2px 10px 0px #0000001a;
      width: 100%;
      margin-top: 10px;

      .phone-invoice {
        display: flex;
        flex-direction: column;
        width: 100%;

        .invoice-number {
          display: flex;
          align-items: center;
          margin-top: 24px;
          .label {
            background: #ffe4c5;
            border: none;
            padding: 6px 15px;
            border-radius: 30px;
            font-size: 10px;
            color: #dd7700;
            margin-top: -13px;

            margin-left: 3em;
          }
        }

        .invoice-order {
          display: flex;
          align-items: center;
          height: 65px;
          a {
            text-align: center;
            margin-left: 2.5em;
            margin-top: -1.5em;
            margin-right: 15px;
            padding: 0.5em 1em;
            border-radius: 0.5em;
            text-transform: capitalize;
            color: #000;
            background: #f2f9eb;
          }
        }

        .invoice-number span {
          margin-left: 15px;
          color: #223263;
        }

        .invoice-number h6 {
          font-weight: bold;
          margin-top: -0.5em;
        }

        .invoice-item p,
        .invoice-price p,
        .invoice-store p,
        .invoice-shipping p,
        .invoice-date p,
        .invoice-order p {
          color: #a0b1c4;
        }
        .invoice-item span {
          margin-left: 50px;
          color: #223263;
        }
        .invoice-price span {
          margin-left: 60px;
          color: #40bfff;
        }
        .invoice-date span {
          margin-left: 50px;
          color: #223263;
        }
        .invoice-store span {
          margin-left: 55px;
          color: #223263;
        }
        .invoice-shipping span {
          margin-left: 25px;
          color: #223263;
        }
        .invoice-order span {
          margin-left: 20px;
          color: #223263;
        }
      }
    }
  }
}

.invoice-overview-details {
  width: 100%;
  .cardhead {
    display: flex;
    width: 100%;

    select {
      width: 50%;
      border: none;

      &:hover {
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
      @include respond(tab-port) {
        display: flex;
        width: 8em;
      }
      @include respond(phone) {
        display: none;
        width: 100% !important;
      }
    }

    input {
      background-image: url('../../assets/icons/SearchIcon.png');
      background-repeat: no-repeat;
      background-position-x: 0.7em;
      background-position-y: 0.8em;
      background-size: 1.6em;
      background-color: $color-gray-light-4;
      border: 3em solid $color-gray-light;
      border: none;
      padding-left: 3em;
      width: 25em;
      &::placeholder {
        color: $color-gray;
        padding-left: 0.1em;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
      @include respond(tab-port) {
        width: 25em;
      }
      @include respond(phone) {
        display: none;
      }
    }

    @include respond(tab-land) {
      width: 100% !important;
    }

    @include respond(phone) {
      display: none;
    }
    .click {
      cursor: pointer;
    }
  }

  .invoice-detail {
    width: 100%;
    .invoice-detail-info {
      display: flex;
      .invoice-detail-description {
        display: flex;
        flex-direction: column;
        flex: 0.6;
        .invoice-detail-number {
          .name {
            color: #58920b;
            font-weight: 600;
          }
          p {
            color: #a0b1c4;
            font-size: 1.2em;
          }
          .invoice-number {
            font-weight: 600;
          }
        }

        .invoice-detail-issued {
          display: flex;
          margin-top: 1em;
          .invoice-detail-issued_date {
            margin-right: 5em;
            h6 {
              color: #a0b1c4;
            }
            p {
              font-size: 1em;
            }
            @include respond(phone) {
              display: flex;
              flex-direction: column;
            }
          }
          .invoice-detail-issued_due {
            h6 {
              color: #a0b1c4;
            }
          }
        }

        .invoice-detail-sender {
          display: flex;
          margin-top: 1.5em;
          h5 {
            color: #a0b1c4;
          }
          .sender-info {
            margin-left: 6.5em;
            color: #a0b1c4;
            .sender-name {
              color: #000;
            }
            p {
              font-size: 1em;
            }

            @include respond(phone) {
              display: flex;
              flex-direction: column;
              margin-left: 3em;
              font-size: 12px;
            }
          }
          .invoice-detail-issued_due {
            h6 {
              color: #a0b1c4;
            }
          }
        }
        .invoice-detail-recipent {
          display: flex;
          margin-top: 2em;
          h5 {
            color: #a0b1c4;
          }
          .recipent-info {
            margin-left: 5.3em;
            color: #a0b1c4;
            .sender-name {
              color: #000;
            }
            @include respond(phone) {
              margin-left: 1.5em;
              // font-size: 12px;
            }
          }
        }
        @include respond(phone) {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
      }
      .invoice-detail-image {
        display: flex;
        flex: 0.4;
        align-items: center;
        justify-content: center;
        height: 25em;
        width: 90%;
        background: #c4c4c4;
        @include respond(phone) {
          display: none;
        }
      }
    }
    .table-infos {
      margin-top: 3em;
      width: 100%;

      .table-description {
        display: flex;
        background: #f2f9eb;
        align-items: center;
        justify-content: center;
        margin-top: 0.3em;
        padding: 0.5em;

        .description-header {
          display: flex;
          flex: 0.2;
          @include respond(phone) {
            h6 {
              font-size: 12px;
              padding-top: 0.9em;
            }
          }
        }
        .description-header-info {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-end;
          padding-top: 0.3em;
          flex: 0.8;

          .vatz {
            margin-left: -1em;

            @include respond(phone) {
              display: none;
              margin-right: 0;
            }
          }
        }

        @include respond(phone) {
          display: flex;
          flex: 1;
          font-size: 12px;
        }
      }
      .table-descriptions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.3em;
        padding: 0.5em;

        .description-header {
          display: flex;
          flex: 0.2;
          @include respond(phone) {
            h6 {
              font-size: 8px;
            }
          }
        }
        .description-header-info {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-top: 0.3em;
          flex: 0.8;
          .vatz {
            margin-left: -1em;

            @include respond(phone) {
              display: none;
              margin-right: 0;
            }
          }

          @include respond(phone) {
            font-size: 8px;
          }
        }
      }
      .table-info-total {
        display: flex;
        margin-top: 1em;
        flex-direction: column;

        .total-price {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 6em;

          p {
            @include respond(phone) {
              font-size: 12px;
            }
          }
          p > span {
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin-left: 2em;
            @include respond(phone) {
              font-size: 12px;
            }
          }
          @include respond(phone) {
            margin-right: 2em;
          }
        }

        .shipping {
          display: flex;
          align-items: flex-end;
          font-size: 12px;

          @include respond(phone) {
            font-size: 10px;
          }
        }

        @include respond(phone) {
          display: flex;
          flex-direction: column;
        }
      }
      @include respond(phone) {
        margin-top: 1em;
      }
    }
  }
  .btn-head {
    margin-bottom: 6em;
    display: flex;

    .btnNavigation {
      display: flex;
      margin-bottom: 1em;
      .btn-download {
        border: 1px solid #58920b;
        padding: 0.5em 1em;
        border-radius: 8px;
        color: #58920b;
      }
      .btn-edit {
        border: 1px solid #c4a0a6;
        color: #c4a0a6;
        padding: 0.5em 1em;
        border-radius: 8px;
      }
      .btn-send {
        background: #58920b;
        color: #fff;
        padding: 0.5em 1em;
        border-radius: 8px;
      }
    }
    .powered {
      margin-left: 8em;
      @include respond(phone) {
        // margin-left: 1em;
        display: none;
      }
    }
    @include respond(phone) {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5em;
    }
  }
  .items-info {
    width: 100%;
    box-shadow: 0px 2px 10px 0px #0000001a;
    padding: 1em;
    .items-details {
      padding: 2em;
      display: flex;
      flex-direction: column;

      h4 {
        color: #223263;
        font-size: 20px;
        font-weight: 700;
        @include respond(phone) {
          display: none;
        }
      }
      .items-detail {
        display: flex;
        width: 100%;
        margin-top: 2em;

        .item-details-image {
          height: 15em;
          width: 95%;
          background: #f6f6f6;
          flex: 0.2;

          @include respond(phone) {
            flex: 1;
            margin-bottom: 1.8em;
            width: 100%;
          }
        }

        .items-details-shipping {
          display: flex;
          flex: 0.8;
          margin-left: 1em;

          .item-name {
            display: flex;
            width: 100%;
            flex: 0.5;
            flex-direction: column;
            .item-name-number {
              display: flex;
              h5 {
                margin-left: 1em;
                font-size: 12px;
              }
              .name {
                color: #223263;
                font-size: 14px;
                font-weight: 700;
              }
              p {
                margin-left: 2em;
                font-size: 12px;
              }
              .qty {
                margin-left: 13em;
              }
              .type {
                margin-left: 10em;
              }
              .vat {
                margin-left: 13em;
              }
              .number {
                color: #223263;
                @include respond(phone) {
                  margin-left: 7em;
                }
              }
              .price {
                margin-left: 12em;
                color: #40bfff;
              }
            }
            .item-warranty {
              font-size: 14px;
              @include respond(phone) {
                display: flex;
                margin-left: 1em;
                margin-bottom: 1em;
                p {
                  margin-left: 7.5em;
                }
              }
            }
          }
          .item-details {
            display: flex;
            width: 100%;
            flex: 0.5;
            flex-direction: column;
            .header {
              color: #223263;
              font-size: 14px;
              font-weight: 700;
            }
            .item-details-name {
              .item-name-info {
                display: flex;
                margin-top: 1em;
                h5 {
                  font-size: 12px;
                  color: #a0a1c4;
                  width: 40%;
                }
                p {
                  font-size: 12px;
                  margin-left: 2em;
                  color: #a0a1c4;
                }
                .name {
                  margin-left: 2.3em;
                }
                .phone {
                  margin-left: 1.8em;
                }
                address {
                  font-size: 12px;
                  margin-left: 5em;
                  color: #a0a1c4;
                }
              }
            }
            .return-policy {
              font-size: 14px;
              @include respond(phone) {
                display: flex;
                h6 {
                  text-decoration: underline;
                }
                p {
                  margin-left: 4.8em;
                }
              }
            }

            @include respond(phone) {
              margin-left: 0.7em;
            }
          }
          @include respond(phone) {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-left: 0.1em;
          }
        }
        @include respond(phone) {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-top: 0.1em;
          margin-top: 0.1em;
        }
      }
      @include respond(phone) {
        padding: 0.1em;
      }
    }
    @include respond(phone) {
      box-shadow: none;
    }
  }

  .item-specification {
    margin-top: 1em;
    .item-spec {
      display: flex;
      justify-content: center;

      .item-features {
        display: flex;
        flex-direction: column;
        flex: 0.5;
        h5 {
          color: #223263;
          font-size: 14px;
          font-weight: 700;
          margin: 1em;
        }
        .item-feature {
          margin-top: 1em;

          h6 {
            width: 70%;
            height: 3em;
            text-align: center;
            padding-top: 0.8em;
            background: #a0b1c4;
            @include respond(phone) {
              text-align: start;
              padding-left: 1em;
            }
          }
        }
        .item-screen {
          display: flex;
          margin-top: 1em;

          h6 {
            margin-right: 6em;
            font-size: 12px;
            @include respond(phone) {
              font-weight: bold;
              margin-right: 0.8em;
            }
          }
          @include respond(phone) {
            font-size: 12px;
          }
        }
      }
      .item-color {
        display: flex;
        flex-direction: column;
        flex: 0.5;

        h6 {
          color: #223263;
          font-size: 12px;
          font-weight: 700;
          margin: 1em;
        }

        .item-color-header {
          width: 85%;
          height: 3em;
          display: flex;
          justify-content: space-between;
          text-align: center;
          padding-top: 0.8em;
          background: #f2f9eb;
          padding-left: 0.5em;
          padding-right: 0.5em;
          @include respond(phone) {
            width: 95%;
          }
        }

        .item-color-content {
          display: flex;
          justify-content: space-between;
          width: 85%;
          padding-left: 1em;
          padding-right: 1em;
          margin-top: 1em;
          text-align: start;

          @include respond(phone) {
            width: 95%;
          }
        }
      }
      @include respond(phone) {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
    .other-details {
      margin-top: 1em;
      width: 30%;
      h6 {
        color: #223263;
        font-size: 14px;
        font-weight: 700;
      }
      p {
        font-size: 12px;
        line-height: 1.8em;
      }
      p > span {
        font-size: 12px;
        font-weight: 600;
      }
      @include respond(phone) {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
    @include respond(phone) {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  }

  @include respond(phone) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  @include respond(tab-port) {
    margin-top: 3em;
  }
}
