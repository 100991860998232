.tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2em;
}

.tabsBtn {
    color: $color-gray;
    font-size: 1em;
    font-weight: 900;
    border: none;
    border-bottom: 0.4em solid $color-white;
    width: 100% !important;
    padding: 1em;
    text-align: center;
    background-color: inherit;
}

.tabsBtn:hover {
    cursor: pointer;
}

.tabsBtn:focus {
    outline: none;
    border-bottom: 0.4rem solid $color-primary;
    color: $color-primary;
}

.tabsBtn.active {
    outline: none;
    border-bottom: 0.4rem solid $color-primary;
    color: $color-primary;
}