// // Overrides
$theme-colors: ( 'primary': $color-primary, 'secondary': $color-secondary, 'danger': $color-red);
$input-padding-y: .75em;
$form-check-input-checked-bg-color: $color-primary;
// .form-control {
//   padding: 1rem 0.5rem !important;
// }
// $badge-font-size:  1.2rem;
// $badge-padding-y: 1rem;
// $badge-padding-x: 1.5rem;
// .btn {
//   padding: 1rem 2.5rem !important;
// }
// $font-size-base: 1.4rem;
// Bootstrap
@import '~bootstrap/scss/bootstrap';