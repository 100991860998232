.card {
    border: 1px solid $color-gray !important;
    border-radius: 0.3em !important;
    .card-head {
      border-bottom: 1px solid $color-gray;
      padding: 2em;
      font-weight: 500;
    }
  }
  
  .deliver-details-header {
    display: flex;
    justify-content: space-between;
  }
  
  .section {
    overflow: hidden;
    box-shadow: $shadow-2;
    border-radius: 4px;
    position: relative;
    .section-header {
      height: 12vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.5em;
      padding-left: 4em;
      padding-right: 4em;
      align-items: center;
      background-color: $color-blue-light;
      @media only screen and (max-width: 600px) {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
      &-img {
        width: 100px;
        height: 100%;
      }
      &-text {
        font-weight: bold;
  
        @media only screen and (max-width: 600px) {
          font-size: 1em;
        }
      }
    }
  
    &-content {
      width: 100%;
    }
  }
  
  .category-card {
    box-shadow: $shadow-2;
    width: 150px;
    height: 200px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      transform: scale(1.02);
    }
    .image {
      width: 80%;
      height: 80%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: $color-white;
    }
  
    .header {
      width: 80%;
  
      h6 {
        font-weight: bold;
      }
    }
  }
  
  .new-on-min-card {
    box-shadow: $shadow-2;
    width: 260px;
    height: 130px;
    border-radius: 5px;
    padding-top: 1.5em;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      transform: scale(1.02);
    }
  
    .discount {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  
    .image {
      width: 80%;
      height: 80%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: $color-white;
      margin-bottom: 8px;
      margin-left: 2em;
    }
  
    .header {
      width: 80%;
      margin-left: 2em;
      text-align: center;
      h6 {
        font-weight: bold;
      }
    }
  }
  
  .product-card {
    width: 220px;
    //   border: $gray-line;
    height: 320px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    justify-content: center;
  
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      transform: scale(1.02);
    }
    @media only screen and (max-width: 576px) {
      width: 180px;
    }
    .image {
      border-radius: 10px;
      box-shadow: $shadow-2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: $color-white;
      width: 100%;
      height: 80%;
    }
  
    .header {
      height: 20%;
      display: flex;
      flex-direction: column;
      text-align: left;
      p {
        margin-bottom: 0;
      }
    }
  }
  
  .category-card-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1em;
    justify-items: center;
    align-items: center;
  
    @media only screen and (max-width: 760px) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 0.5em;
    }
  }
  
  .product-card-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1em;
    justify-items: center;
    align-items: center;
  
    @media only screen and (max-width: 760px) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 0.3em;
    }
  }
  
  .order-item-card {
    position: relative;
    border-radius: 8px;
    border: $gray-line;
    padding: 2rem;
    width: 95%;
    margin: auto;
    box-shadow: $shadow-2;
    margin-bottom: 1rem;
    .minimal-content {
      display: flex;
      column-gap: 1.5rem;
      margin-bottom: 1.5rem;
  
      @media only screen and (max-width: 780px) {
        flex-direction: column;
        padding: 0.5rem;
      }
  
      @media only screen and (max-width: 520px) {
        flex-direction: column;
        padding: 0;
        margin-bottom: 0.5rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
      .content {
        flex: 1;
  
        display: flex;
        column-gap: 2rem;
  
        @media only screen and (max-width: 520px) {
          flex-direction: column;
          padding: 0;
          column-gap: 0.5rem;
        }
      }
  
      .actions {
        flex: 0 0 25%;
        // padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        column-gap: 2rem;
        row-gap: 1rem;
        @media only screen and (max-width: 780px) {
          flex: 1;
  
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          flex-direction: row;
          justify-content: space-between;
        }
        @media only screen and (max-width: 520px) {
          flex-direction: column;
          padding: 0;
          column-gap: 0.5rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        // border: $gray-line;
        border-radius: 8px;
      }
    }
  
    .additional-details {
      border: $gray-line;
    }
  
    .expand {
      position: absolute;
      bottom: 0;
      left: 50%;
      color: $color-gray;
      transform: translateX(-50%);
    }
  }
  
  // .order-item-image {
  //   position: relative;
  // }
  