.manufacturer {
    .cardhead {
        @include respond(tab-port) {
            padding: 1.5em !important;
        }
        button {
            float: right;
            @include respond(phone) {
                float: initial;
            }
        }
        input {
            background-image: url("../../assets/icons/SearchIcon.png");
            background-repeat: no-repeat;
            background-position-x: .7em;
            background-position-y: .8em;
            background-size: 1.6em;
            background-color: $color-gray-light-4;
            // border: .2px solid $color-gray-light !important;
            border: none;
            padding-left: 3em;
            width: 100%;
            &::placeholder {
                color: $color-gray;
                padding-left: .1em;
            }
            &:focus,
            &:active {
                outline: none !important;
            }
        }
    }
    .center-box {
        width: 45% !important;
        @include respond(tab-land) {
            width: 90% !important;
        }
        .circle {
            background-color: $color-gray-light-2;
            height: 5em;
            width: 5em;
            border-radius: 100%;
            align-content: center;
            margin: auto;
        }
        p {
            text-align: center;
            &:nth-of-type(1) {
                font-size: 1.6em;
                font-weight: 400;
            }
            &:nth-of-type(2) {
                color: $color-gray;
            }
        }
        button {
            padding: .8em;
        }
    }
}

.list-manufacturers {
    .cardhead {
        width: 100% !important;
        select {
            // width: 15em;
            margin-right: 2em;
            border: none;
            &:hover {
                cursor: pointer;
            }
            &:focus,
            &:active {
                outline: none;
            }
            @include respond(phone) {
                width: 100% !important;
            }
        }
        input {
            background-image: url("../../assets/icons/SearchIcon.png");
            background-repeat: no-repeat;
            background-position-x: .7em;
            background-position-y: .8em;
            background-size: 1.6em;
            background-color: $color-gray-light-4;
            border: 3em solid $color-gray-light;
            border: none;
            padding-left: 3em;
            width: 100%;
            height: 3em;
            &::placeholder {
                color: $color-gray;
                padding-left: .1em;
            }
            &:focus,
            &:active {
                outline: none !important;
            }
        }
    }
    .status {
        .status-single {
            background-color: inherit;
            border: none;
            text-align: center;
            padding: .6em;
            color: $color-gray-light;
            margin-right: 1.3em;
            small {
                color: $color-black;
                font-size: .7em;
            }
            &.active {
                border-bottom: .1em solid $color-primary;
            }
            &:hover,
            &:active,
            &:focus {
                border-bottom: .1em solid $color-primary;
            }
        }
    }
    .filter {
        margin-bottom: 2em;
        @include respond(tab-port) {
            padding: 1em;
            margin: auto;
        }
        .filter-single {
            display: flex;
            border: none;
            background-color: $color-primary-light;
            border-radius: .5em;
            margin-right: 1em;
            height: 3.5em;
            &:hover {
                cursor: pointer;
            }
            @include respond(tab-land) {
                margin-bottom: 1em;
            }
            select {
                background-color: inherit;
                border: none;
                font-size: .9em;
                &:active,
                &:focus {
                    outline: none !important;
                }
                @include respond(phone) {
                    width: 100% !important;
                }
            }
            .icon {
                margin-top: 1.2em !important;
                margin-right: 1em;
            }
        }
        button {
            border: none;
            background-color: $color-primary-light;
            border-radius: .5em;
            width: 100% !important;
            height: 4em;
            font-size: .9em;
            @include respond(tab-land) {
                margin-left: -.8em;
            }
        }
    }
}