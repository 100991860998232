@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
  font-family: 'Roboto Sans', sans-serif;
}


.card-min-color {
    background-color: #F2F9EB !important;
}

.horizontal-scrollable {
  overflow: auto;
  /* white-space: nowrap; */
  /* float: left; */
  height: 300px;
  width: 10px;
}

.close-category-modal{
  float: right;
}