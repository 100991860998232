// .min-cropper {
//   //   display: flex;
//   //   width: 100%;
//   //   height: 80vh;
// }

.crop-container {
  position: relative;
  height: 20rem;
}

.crop-preview {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  background-color: #000;
  padding: 0.5rem;
}

.image-cropper-done-btn {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
