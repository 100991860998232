// .dashboard {
//     // background-color: #FCFDFB;
// }

// Header
.headers {
	width: 100%;
	padding: 20px;
	position: fixed !important;
	z-index: 1000;
	background-color: #f8f9fa;
	top: 0em;
	left: 0;
	@media screen and (max-width: 500px) {
		background-color: #ffffff;
	}

	img {
		margin-right: 1em;
		margin-left: 1em;
	}
	#dropdown-user-button {
		border: none;
		outline: none;
		background-color: #f8f9fa;
		margin-right: 1rem;
	}
	@include respond(phone) {
		margin-top: 0.6em;
		width: 100%;
		position: fixed;
	}
	/* Icon 1 */
	#nav-icon1 {
		width: 2.5em;
		height: 2em;
		position: relative;
		// margin: 50px auto;
		margin-right: 1.5em;
		margin-top: 0.2em;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
		display: none;
		@include respond(phone) {
			display: block;
		}
		@include respond(tab-port) {
			display: block;
		}
		& span {
			display: block;
			position: absolute;
			height: 0.3em;
			width: 100%;
			background: $color-black;
			border-radius: 9px;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: 0.25s ease-in-out;
			-moz-transition: 0.25s ease-in-out;
			-o-transition: 0.25s ease-in-out;
			transition: 0.25s ease-in-out;
		}
		& span:nth-child(1) {
			top: 0px;
		}
		& span:nth-child(2) {
			top: 0.8em;
		}
		& span:nth-child(3) {
			top: 1.6em;
		}
		&.open span:nth-child(1) {
			top: 18px;
			-webkit-transform: rotate(135deg);
			-moz-transform: rotate(135deg);
			-o-transform: rotate(135deg);
			transform: rotate(135deg);
		}
		&.open span:nth-child(2) {
			opacity: 0;
			left: -60px;
		}
		&.open span:nth-child(3) {
			top: 18px;
			-webkit-transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			-o-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
	@include respond(tab-land) {
		position: fixed;
		width: 100%;
		z-index: 1000;
	}
	@include respond(phone) {
		position: fixed;
		width: 100%;
		z-index: 1000;
		margin-top: -0.1em;
		top: 0%;
	}
}

// Side Nav
.side-nav {
	// padding: 4em 0 0 0em;
	background: #fcfdfb;
	left: 0;
	// width: 16.5% !important;
	// margin: 2em 0 0 1.2em;
	position: fixed;
	@media (min-width: 48em) {
		top: 6em;
	}
	@include respond(phone) {
		// padding: 2em !important;
		left: 0;
		margin-bottom: 1em;
		position: fixed;
		z-index: 999;
		background-color: $color-primary-light;
		bottom: 0;
		width: 100% !important;
		height: 10vh;
		margin: 0;
		display: flex;
	}
	&.active {
		@include respond(tab-port) {
			left: 40em;
		}
		@include respond(phone) {
			left: 30em;
		}
	}
	ul {
		list-style-type: none;
		position: relative;
		@include respond(phone) {
			display: flex;
		}
		.support {
			// margin-left: -2em;
			margin-top: 1em !important;
			margin-bottom: 1em;
		}
		li {
			color: $color-gray;
			margin-bottom: 1.4em;
			display: flex;
			// margin-left: -2em;
			@include respond(phone) {
				flex-flow: column;
				margin-right: 3em;
				margin-top: -1.2em;
				text-align: center;
				font-size: 10.5px;
			}
			.icon {
				margin-right: 1em;
				margin-top: -0.01em !important;
				@include respond(phone) {
					margin: auto;
				}
			}
			&.no-display-mobile {
				@include respond(phone) {
					display: none;
				}
			}
			&:hover {
				background-color: $color-primary;
				color: $color-white;
				// padding: .7em;
				padding-left: 1rem;
				cursor: pointer;
				border-top-left-radius: 2em;
				border-bottom-left-radius: 2em;
			}
			&:hover .icon {
				color: $color-white;
			}
			&.active {
				background-color: $color-primary;
				color: $color-white;
				// padding: .7em;
				padding-left: 1rem;
				cursor: pointer;
				border-top-left-radius: 2em;
				border-bottom-left-radius: 2em;
				& .icon {
					color: $color-white;
					@include respond(phone) {
						color: $color-gray;
						margin: auto;
					}
				}
				@include respond(phone) {
					background-color: inherit;
					color: $color-gray;
					padding: 0;
				}
			}
		}
	}
	.bottom-nav {
		@media screen and (max-width: 769x) {
			display: none;
		}
	}
	.accordion-button {
		background-color: #fcfdfb;
		padding: 1rem 0.1rem;
		color: #9da8b6;
		border: none;
		outline: none;
	}
	.accordion-item {
		background-color: #fff;
		border: none;
	}
	.accordion-body {
		padding: 1rem 1.25rem;
		background-color: #6f972d;
	}
	.accordion-button::after {
		flex-shrink: 0;
		width: 1.25rem;
		height: 1.25rem;
		margin-left: auto;
		content: "";
		// background-image: url( xmlns="http://www.w3.org/2000/svg");
		background-repeat: no-repeat;
		background-size: 1.25rem;
		transition: transform 0.2s ease-in-out;
	}
	.important-info {
		background-color: $color-primary-light;
		width: 90%;
		height: 12em;
		padding: 2em;
		border-radius: 0.5em;
		text-align: center;
		p {
			margin-top: 3em;
		}
	}
}
.modal-body{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
.MuiCard-root {
	// margin-left: 9em;
  margin: auto;
//   width: 90%;
  top: 4em;
	@include respond(phone) {
		margin-left: 0;
	}
}
.MuiCardContent-root {
	@include respond(phone) {
		padding: 0;
	}
}
.MuiStepper-alternativeLabel {
	@include respond(phone) {
		margin-left: -2em;
	}
}
.MuiPaper-root{
	color: #6f972d;
	box-shadow: none;
}
.MuiFormControl-root{
    width: 100%;
}
.MuiFormControl-root {
    width: 100%;
}
.MuiButton-root {
    width: 100%;
}
.store-list {
	font-size: 12px;
	color: #ffffff;
	cursor: pointer;

	:hover {
		width: 100%;
		height: 31px;
		background: #f2f9eb;
		cursor: pointer;
		padding: 0.5rem;
		color: #6f972d;
	}
}
// Progress Bar
.CircularProgressbar-path {
	stroke: $color-primary !important;
}

.CircularProgressbar .CircularProgressbar-text {
	fill: $color-black !important;
	font-weight: bold;
}

.CircularProgressbar .CircularProgressbar-trail {
	stroke: #a0b1c4;
}

// footer
.footer {
	.footer-text {
		display: flex;
		align-items: center;
		text-align: justify;
		color: #a0b1c4;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 272.5%;
	}
	.upper-text {
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: justify;
		color: #252b1d;
	}
	.text-discription {
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #a0b1c4;
	}
	.btn-sub {
		background: #f69033;
		border-radius: 10px;
		color: #ffffff;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		height: 50px;
		width: 146px;
	}
	.nav {
		margin-left: 2em;

		// @include respond(phone) {
		//     margin-left: .2em;
		// }
		@include respond(tab-land) {
			margin-left: 0.2em;
		}
		ul {
			list-style-type: none;
			@include respond(phone) {
				display: none;
			}
			li {
				// margin-left: -2em;
				margin-bottom: 1em;
			}
		}
		p {
			@include respond(tab-port) {
				margin-top: 1em;
			}
		}
	}
	.download {
		width: fit-content;
	}
	.terms {
		margin: auto;
		width: fit-content;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		p {
			@include respond(phone) {
				margin: 0.5em !important;
			}
		}
	}
}

// Side Nav 2
.side-bar {
	position: fixed;
	@include respond(tab-port) {
		position: relative !important;
	}
	.CircularProgressbar-path {
		stroke: $color-primary !important;
	}
	.CircularProgressbar .CircularProgressbar-text {
		fill: $color-black !important;
		font-weight: bold;
	}
	.CircularProgressbar .CircularProgressbar-trail {
		stroke: #a0b1c4;
	}
	.profile-progress {
		margin: 0 0.7em;
		text-align: center;
		width: 100%;
	}
	nav {
		background-color: $color-white;
		border: none !important;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
		padding: 1em;
		border-radius: 0.5em;
		height: 60%;
		// margin: 0 2em;
		// width: 90%;
		ul.nav-menu {
			list-style-type: none;
			margin-right: 1em;
			background-color: $color-primary;
			width: 100% !important;
			border-radius: 0.3em;
			padding-top: 1em;
			padding-bottom: 0.4em;
			margin-bottom: 2em !important;
			p {
				color: $color-white;
				font-size: 1.2em;
			}
			a {
				color: $color-black;
			}
			li.nav-item {
				color: $color-primary-light;
				margin-bottom: 1.6em;
				padding: 0.5em;
				// margin-left: -2em;
				padding-left: 2em;
				&:hover {
					background-color: $color-white;
					color: $color-primary;
					cursor: pointer;
				}
				&.active {
					background-color: $color-white;
					color: $color-primary;
				}
			}
			// li.nav-item {
			//     &:hover:not(li.active) {
			//         cursor: pointer;
			//         color: $color-primary;
			//     }
			//     &::before {
			//         content: '';
			//         display: inline-block;
			//         width: 15px;
			//         height: 15px;
			//         -moz-border-radius: 7.5px;
			//         -webkit-border-radius: 7.5px;
			//         border-radius: 7.5px;
			//         border: 1px solid #A0B1C4;
			//         margin-right: .5em;
			//     }
			// }
			// li.nav-dropdown:hover,
			// li.active.nav-dropdown:hover {
			//     padding: .5em;
			//     background-color: $color-white !important;
			//     color: $color-white !important;
			//     border-radius: .3em;
			//     &::before {
			//         display: none;
			//     }
			//     .nav-dropdown-menu {
			//         height: auto;
			//     }
			// }
			// li.active.nav-dropdown {
			//     background-color: $color-primary !important;
			//     padding: .5em;
			//     color: $color-white;
			//     border-radius: .3em;
			//     &::before {
			//         display: none;
			//     }
			//     .nav-dropdown-menu {
			//         height: auto;
			//     }
			// }
			// li.active:not(li.active.nav-dropdown) {
			//     color: $color-primary;
			// }
			// .nav-dropdown-menu {
			//     background-color: $color-primary !important;
			//     color: $color-white;
			//     height: 0;
			//     overflow: hidden;
			//     transition: height 2s;
			// }
			// li.nav-dropdown-item {
			//     font-size: 1em;
			//     margin-bottom: 2.4em;
			//     opacity: 0.6;
			//     &:hover {
			//         cursor: pointer;
			//         opacity: 1;
			//     }
			//     &:nth-of-type(1) {
			//         margin-top: .7em;
			//     }
			//     &.sub-active {
			//         opacity: 1;
			//     }
			// }
			// li.nav-dropdown {
			//     margin-top: 1.3em;
			// }
		}
		@include respond(phone) {
			display: none;
		}
	}
}
// .settings{
//     .store-group{
//         display: none;
//     }
// }

.main-content.mt-5.w-75 {
	margin: auto;
}
.main-content {
	margin-top: 4em;
	@include respond(phone) {
		margin: auto;
		left: 5em;
	}
}

// .main-content {
//   @include respond(phone) {
//     margin-left: 1.4em !important;
//     margin-right: -1em;
//   }
// }

.dropdown-menu.logout-dropdown {
	z-index: 999 !important;
}
.store-details {
	font-size: 1.4em;
	text-transform: uppercase;
	font-weight: bold;
}
.percentage {
	@media screen and (min-width: 550px) {
		display: none;
	}
}
.accordion-button:not(.collapsed) {
	color: none;
	background-color: #ffffff !important;
	box-shadow: none;
}
.toggle-navigation {
	position: relative;
	top: 175px;
}

.prev {
	color: #6f972d;
	position: relative;
	top: 20px;
	//  left:10px;
}
.round {
	background: #6f972d;
	border-radius: 12px;
	width: 20px;
	height: 20px;
}

.count-bg {
	width: 100px;
	height: 320px;
	background: #fcfdfb;
	border: 1px solid #d8e3c7;
	box-sizing: border-box;
	border-radius: 10px;
}

.bg-product {
	width: 101px;
	height: 320px;
	opacity: 0.5;
	border: 1px solid rgba(111, 151, 45, 0.5);
	box-sizing: border-box;
	border-radius: 10px;
}
.bg-product-mobile {
	width: 100%;
	height: 100%;
	opacity: 0.5;
	border: 1px solid rgba(111, 151, 45, 0.5);
	box-sizing: border-box;
	border-radius: 10px;
	position: relative;
	left: 10px;
}
.over-view {
	width: 100%;
	border-radius: 10px;
	border: 1px solid #6f972d80;
	position: relative;
	left: 10px;
}

.storePen {
	color: #6f972d;
}

.bg-allAccount {
	width: 809px;
	height: 380px;
	background: #f2f9eb;
}
.store-creation {
	width: 80%;
	height: 100%;
	background: #f2f9eb;
	position: relative;
	left: 10px;
}

.accordion-button:not(.collapsed) {
	color: #0c63e4;
	background-color: none;
	box-shadow: none;
}

.storeList {
	margin: auto;
	width: 73%;
	height: 5%;
	position: relative;
	left: 70px;
	color: #7ba832;
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	@media screen and (max-width: 500px) {
		margin-top: 2rem;
		position: relative;
		left: -12px;
	}
}
.circle {
	background-color: #7ba832;
	color: #ffffff;
	border-radius: 20px;
}
.store-bg-card {
	background: #ffffff;
	border: none;
	box-sizing: border-box;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	width: 90%;
	height: 90%;
	position: relative;
	left: 4px;
	margin: auto;

	@media screen and(max-width: 500px) {
		background: none;
		border: none;
		box-sizing: none;
		box-shadow: none;
		width: 100%;
		border-radius: none;
	}
}

.modal-done {
	background: #6dab00;
	border-radius: 4px;
	width: 170px;
	height: 35px;
	border: 1px solid #6dab00;
	color: white;

	@media screen and(max-width:420px ) {
		width: 140px;
	}
	@media screen and(max-width:280px ) {
		width: 120px;
	}
}
.modal-cancle {
	background: #ffffff;
	border-radius: 4px;
	width: 170px;
	height: 35px;
	border: 1px solid #0052ce;
	color: #0052ce;

	@media screen and(max-width:420px ) {
		width: 140px;
	}
	@media screen and(max-width:320px ) {
		width: 120px;
	}
}

// Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
// counteract the pointer-events: none; in the .modal-dialog
.modal-dialog {
	top: 80px;
}

.form-body {
	margin-left: 2rem;
	margin-right: 2rem;
}

.form-body-ui {
	margin: auto;
	width: 97%;
}
.backward {
	width: 110.39px;
	height: 50px;
}
.description {
	@media screen and (max-width: 500px) {
		font-size: 14px;
	}
}

.store-profile {
	width: 100%;
	height: 70px;
	background: #f2f9eb;
	@media screen and(max-width:500px) {
		font-size: 13px;
	}
}
.circle-store {
	border-radius: 10px;
	width: 20px;
	height: 20px;
	background: #ffffff;
	font-size: 11px;
	@media screen and(max-width:500px) {
		font-size: 8px;
		width: 15px;
		height: 15px;
	}
}
.select-country {
	@media screen and (max-width: 500px) {
		display: flex;
		flex-direction: row;
	}
}
.store-logo {
	border: 5px dashed #7ba832;
	box-sizing: border-box;
	border-radius: 30px;
	transform: matrix(1, 0, 0, 1, 0, 0);
	width: 397.81px;
	height: 322.17px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 217px;
	@media screen and (max-width: 500px) {
		width: 100%;
		height: 227.67px;
	}
}
.checkbutton {
	width: 45px;
	height: 30px;
}

.circle-next {
	border: 2px solid #7ba832;
	border-radius: 15px;
	background: #7ba832;
	color: #ffffff;
}

.cross-bar {
	@include respond(phone) {
		width: 106%;
	}
}
.store-card-number {
	@media screen and (max-width: 500px) {
		display: none;
	}
}
