// Clearfix for float
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// center horizontally and vertically
@mixin center-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Media Query Manager
//

/*
0-600px Phone
600-900px Tablet Portrait
900-1200px Tablet Landscape
1800px +   Big Screen
*/

/*
$breakpoint argument choices
-phone
-tab-port
-tab-land
-big-desktop
*/

// Note: Order matters in media query
// Media Query manager
@mixin respond($breakpoint) {
  @if $breakpoint==smallest {
    @media only screen and (max-width: 320px) {
      //320px
      @content;
    }
  }
  @if $breakpoint==tiny {
    @media only screen and (max-width: 25em) {
      //400px
      @content;
    }
  }
  @if $breakpoint==small-ph {
    @media only screen and (max-width: 31.25em) {
      //500px
      @content;
    }
  }
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint==ipad {
    @media only screen and (max-width: 48em) {
      //768px
      @content;
    }
    @if $breakpoint==tab-port {
      @media only screen and (max-width: 56.25em) {
        //900px
        @content;
      }
    }
    @if $breakpoint==tab-land {
      @media only screen and (max-width: 75em) {
        //1200px
        @content;
      }
    }
  }
  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      //1800px
      @content;
    }
  }
}

// breadcrumbs
@mixin breadcrumbs {
  .breadcrumbs {
    list-style-type: none;
    margin-left: -30px;
    li {
      float: left;
      font-weight: 600;
      &:after {
        content: " >";
        color: $color-black;
        font-weight: 900;
      }
      &:last-child {
        color: $color-primary;
        &:after {
          content: " ";
          color: $color-black;
          font-weight: 900;
        }
      }
    }
  }
}
.create-btn {
  @include respond(phone) {
    display: none;
  }
}

.manage-store {
  @include respond(phone) {
    display: none;
  }
}

.bottom-nav {
  @include respond(ipad) {
    display: none;
  }
}

.desktop-modal {
  @include respond(phone) {
    display: none;
  }
  @include respond(ipad) {
    display: none;
  }
}

.tree {
  @media screen and(min-width:800px ) {
    display: none;
  }
  overflow: scroll;
  height: 300px;
}
