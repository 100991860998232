// Font
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: "inherit";
  font-family: Roboto;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Poppins !important;
  // background-color: $color-gray-light-2;
}

.main-page {
  background-color: $color-gray-light-4 !important;
  margin-bottom: 0.125em;
  @media screen and (max-width: 500px) {
    background-color: #ffffff !important;
  }
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

// line-height
.l-3 {
  line-height: 3 !important;
}

// buttons
.btn-border-primary {
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
  background: inherit;
  &:hover {
    background-color: $color-primary;
    color: $color-white !important;
  }
}

.btn-border-danger {
  border: 1px solid $color-red !important;
  color: $color-red !important;
  background: inherit;
  &:hover {
    background-color: $color-red;
    color: $color-white !important;
  }
}

.btn-border-radius {
  border-radius: 0.2em;
}

.btn-gray {
  background-color: $color-gray !important;
  color: $color-gray-light-2 !important;
}

.btn-border-gray {
  border: 1px solid $color-gray !important;
  color: $color-gray !important;
  margin-right: 1em;
}

// text
.text-gray {
  color: $color-gray !important;
}

.text-blue {
  color: $color-blue !important;
}

.text-gray-light {
  color: $color-gray-light;
}

.text-secondary {
  color: $color-secondary;
}

.text-primary {
  color: $color-primary !important;
}

.text-danger {
  color: $color-red;
}

a {
  text-decoration: none !important;
}

// background
.bg-primary-light {
  background-color: $color-primary-light;
}

.bg-primary {
  background-color: $color-primary;
}

// card
.m-card {
  border: none !important;
  box-shadow: -4px 0px 50px rgba(0, 0, 0, 0.02);
  // margin-left: 0.7em;
  height: 100%;
  border-top-left-radius: 2.5em !important;
  background-color: $color-white;
  @include respond(tab-port) {
    border-top-right-radius: 2.5em !important;
  }
  @include respond(ipad){
    // margin-left: 1.7em;
  }
  @include respond(phone) {
    border: none !important;
    box-shadow: none !important;
  }
}
