// No staff
.no-staff {
    .close {
        border: none;
        background-color: inherit;
        font-size: 2em;
    }
    .cardhead {
        @include respond(tab-port) {
            padding: 1.5em !important;
        }
        button {
            padding: .2em;
            width: 9em;
            &:nth-of-type(1) {
                margin-right: 1em;
            }
            @include respond(phone) {
                height: 2em !important;
            }
        }
        input {
            background-image: url("../../assets/icons/SearchIcon.png");
            background-repeat: no-repeat;
            background-position-x: .7em;
            background-position-y: .8em;
            background-size: 1.6em;
            background-color: $color-gray-light-4;
            // border: .2px solid $color-gray-light !important;
            border: none;
            padding-left: 3em;
            width: 100%;
            &::placeholder {
                color: $color-gray;
                padding-left: .1em;
            }
            &:focus,
            &:active {
                outline: none !important;
            }
        }
    }
   
    .center-box {
        width: 45% !important;
        @include respond(tab-land) {
            width: 90% !important;
        }
        .circle {
            background-color: $color-gray-light-2;
            height: 5em;
            width: 5em;
            border-radius: 100%;
            align-content: center;
            margin: auto;
        }
        p {
            text-align: center;
            &:nth-of-type(1) {
                font-size: 1.6em;
                font-weight: 400;
            }
            &:nth-of-type(2) {
                color: $color-gray;
            }
        }
        .action-btn {
            width: fit-content;
            margin: auto;
            button {
                padding: .5em;
                width: 11em;
                &:nth-of-type(1) {
                    margin-right: 1em;
                }
            }
        }
    }
}
.create-store-mobile{
    @media screen and (max-width: 500px) {
        margin-top: 170px;
        margin-bottom: 50px;
    }
}