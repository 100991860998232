

// Note: Order matters in media query
// Media Query manager
@mixin respond($breakpoint) {
    @if $breakpoint==tiny {
        @media only screen and (max-width: 25em) {
            //400px
            @content;
        }
    }
    @if $breakpoint==phone {
        @media only screen and (max-width: 37.5em) {
            //600px
            @content;
        }
    }
    @if $breakpoint==tab-port {
        @media only screen and (max-width: 56.25em) {
            //900px
            @content;
        }
    }
    @if $breakpoint==ipad {
        @media only screen and (max-width: 48em) {
            //900px
            @content;
        }
    }
    @if $breakpoint==tab-land {
        @media only screen and (max-width: 75em) {
            //1200px
            @content;
        }
    }
    @if $breakpoint==big-desktop {
        @media only screen and (min-width: 112.5em) {
            //1800px
            @content;
        }
    }
}

.show {
    display: block;
  }
  .hide {
    display: none;
  }
