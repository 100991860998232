.table-responsive {
  @include respond(phone) {
    display: none;
  }
}

.dynamic-table {
  overflow: auto !important;
  width: 100%;
  // thead {
  //   background-color: $color-primary-light;
  // }
  th,
  td {
    padding: 0.5em !important;
    color: $color-dark-2 !important;

    font-size: 0.8em;
  }
  td {
    border-bottom: 0.5px solid #a0b1c4;

    color: $color-gray-dark-1 !important;
    // height: 8em;
  }

  // .table-responsive{12-sm|12-md|-lg|-xl|-xxl}

  // .table-responsive{-sm|-md|-lg|-xl|-xxl}
}

.table {
  @include respond(phone) {
    display: none;
  }
}

.pagination {
  float: right;
  margin-top: 1em;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 2em;

  .next {
    border: none !important;
    background-color: inherit !important;
    margin-right: 0.5em;
    margin-left: 0.5em;
    color: $color-black;
    @include respond(phone) {
      display: none;
    }
  }
  .page {
    @include respond(phone) {
      display: none;
    }
  }
  @include respond(phone) {
    display: none;
  }
}
