.settings {
  
    .upload {
        background-color: $color-gray;
        height: 10em;
    }
   
    input {
        background-image: url("../../assets/icons/SearchIcon.png");
        background-repeat: no-repeat;
        background-position-x: .7em;
        background-position-y: .8em;
        background-size: 1.6em;
        background-color: $color-gray-light-4;
        border: none;
        padding-left: 3em;
        width: 35rem;
        height: 2.5rem;
        // ::placeholder{
        //     font-size: 8px;
        //     width: 13px;
        //     height: 13px;
        // }
        @include respond(phone) {
            width: 15em !important;
        }
        &::placeholder {
            color: $color-gray;
            padding-left: .1em;
        }
        &:focus,
        &:active {
            outline: none !important;
        }
    }
    .store-group{
        width: 100%;
        height: 4.313rem;
        background-color: $color-primary-light;  
        justify-content: center;
        text-align: center;  
    
    #A-store{    
        background-color:#FFFFFF ;
        height: 3.063rem;
        width: 14.375rem;
        border-radius: 3px;
        align-items: center;
        text-align: center;
        padding-top: 0.625rem;
    }
    .store-links{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #252B1D;
       
    }
}
    // #storeFirst{
    //     width: 18.688rem;
    //     height: 3.063rem;
    //     background: #FFFFFF;
    //     box-shadow: -4px 0px 50px rgba(0, 0, 0, 0.02);
    //     position: absolute;
    //     left: 303px;
    //     top: 2;

    // }

    .store-profile{
        position: relative;
        width: 100%;
        height: 14.875rem;  
        top: 2.188rem;
        background: #FFFFFF;
        border: 1px solid #EFF2F7;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .edit-details{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin-right: 1.25rem;
            
        }
    }
    .storeProfile{
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin-left: 1.25rem;
    }
    .details{
        margin-left: 3.75rem;
    }
    .title{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    }
    .store-contact{
        padding-bottom: 1.5rem;
        height: 100%;
        position: relative;
        width: 100%;
        // height: 14.875rem;  
        top: 2.188rem;
        background: #FFFFFF;
        border: 1px solid #EFF2F7;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

}
.main-container{
    // width: 100%;
    // height: 100vh;
    // background-color: $color-white;
    padding: 0px 40px 40px 0px;

    .product-store{
        width: 100%;
        padding: 30px 0px 30px 0px;       
        background: #FFFFFF;
        border: 0.5px solid #A0B1C4;
        box-sizing: border-box;
        box-shadow: 0px 2px 30px rgba(144, 152, 176, 0.2);
        border-radius: 5px;
    }
    .store-title{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: $color-primary;
        margin-left: 2.5rem;
       
    }
    .productImg{
        margin-left: 2.5rem;
    }
    .Product-container{
        .product-box{
            width: 227px;
            height: 307px;
            background: #FFFFFF;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
        }
    }
}