// Helpers
// @import 'helpers/functions';
@import 'helpers/mixins';
@import 'helpers/variables';
// Base
@import 'base/animations';
@import 'base/typography';
@import 'base/base';
@import 'base/utils';
// Components
@import 'components/form';
@import 'components/layout';
@import 'components/tabs';
@import 'components/table';
@import 'components/image-cropper';
@import 'components/card';

// Pages
@import 'components/account-overview';
@import 'components/manage-store';
@import 'components/manage-product';
@import 'components/manage-staff';
@import 'components/manage-invoice';
@import 'components/manage-order';
@import 'components/settings';
@import 'components/manage-distributors';
@import 'components/manage-manufacturers';
// Vendors
@import 'vendors/bootstrap';

//Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";